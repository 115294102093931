import React, { useState}  from 'react'
import Logo_img from '../icons/logo.png'
import vs_img from '../icons/vs1.png'
import goright from '../icons/goright.png'
import down_img from '../icons/down.png'
import up_img from '../icons/up.png'
import twitter_img from '../icons/twitter.png'
import github_img from '../icons/github.png'
import '../styles/home.css'

const Home = () => {

    const [faq1, setFaq1] = useState(false);
    const [faq2, setFaq2] = useState(false);
    const [faq3, setFaq3] = useState(false);
    const [faq4, setFaq4] = useState(false);
    const [faq5, setFaq5] = useState(false);
    const [faq6, setFaq6] = useState(false);
    const [faq7, setFaq7] = useState(false);
    const [faq8, setFaq8] = useState(false);

    function redirectToPage() {
        window.open('https://app.constellation.fun', '_blank');
    }
    function handerClick1(){
        setFaq1(!faq1);
        
    }
    function handerClick2(){
        setFaq2(!faq2);
    }
    function handerClick3(){
        setFaq3(!faq3);
    }
    function handerClick4(){
        setFaq4(!faq4);
    }
    function handerClick5(){
        setFaq5(!faq5);
    }
    function handerClick6(){
        setFaq6(!faq6);
    }
    function handerClick7(){
        setFaq7(!faq7);
    }
    function handerClick8(){
        setFaq8(!faq8);
    }


return (
    <div className='home_parent'>
      <div className='home_title'>
        <img src={Logo_img}/>
        <li>Constellation Battle</li>
      </div>
      <div className='home_content'>
        <div className='home_left'>
            <div className='home_left_title'><li>Constellation Battle</li></div>
            <div className='home_left_content'><li>One Community-Interactive Play-to-Earn leisure, entertainment, feed and combat full-chain game based on Fuel Network.</li></div>
            <div className='home_left_app' ><li onClick={()=>redirectToPage()}>Launch App</li><img src={goright}/></div>         
        </div>
        <div className='home_right'>
            <img className='home_right_img' src={vs_img}/>
        </div>
      </div>
      <div className='home_faq'>
        <div className='home_faq_title'>
            <li>FAQ</li>
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick1}>
                <li>What is constellation battle?</li>
                {faq1 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq1 && <div className='faq_contain_content'>
                <li>According to legend, in a distant era, there was a monster that produced only one constellation card every day. Our game--Constellation Battle Game comes from this ancient legend.
                <br/><br/>Users need to mint one monster first. Each monster has its own unique Gene and Weight Value. The Gene is related to the Battle while the Weight Value is related to the Bonus. Once the monster is minted, it has a TOD(time to death) . Before the TOD expires, you need to feed it with one kind of fruit to extend its life. Otherwise it will be in death and won't spit out constellation cards until you revive the monster. The monster randomly produces one certain type of constellation card every day. The type is based on its own genes and random numbers. 
<br/><br/>Users can list duplicate constellation cards in the battle market. Other users can battle against these constellation cards with their own. The winner in battle will get 2 constellation cards. In one epoch of the game , once 12 types of constellation cards are all collected, users can combine a super card(Zodiac Card), holding which users are eligible to enter the bonus pool and get rewards.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick2}>
                <li>What is lucky wheel?</li>
                {faq2 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq2 && <div className='faq_contain_content'>
                <li>Users can spin the lucky wheel once every 24 hours by spending 50 CON to randomly get apples, bananas, accelerator cards for 8 hours, accelerator cards for 16 hours, accelerator cards for 24 hours, or universal constellation cards (which can be converted into any type of constellation cards, up to 100 cards per epoch).
</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick3}>
                <li>What determines the outcome of a battle?</li>
                {faq3 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq3 && <div className='faq_contain_content'>
                <li>The outcome of the battle is related to the Gene of both parties and random numbers. The winning/losing ratio is determined by the Weight Value of both parties. 
<br/>For example,if A has weight 8, and B has weight 2, then A has an 80% chance of winning, while B has a 20% chance of winning.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick4}>
                <li>Can I regenerate my gene and weight?</li>
                {faq4 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq4 && <div className='faq_contain_content'>
                <li>Yes, if you are not satisfied with the monster's Gene and Weight Value, you can regenerate them every 24 hours.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick5}>
                <li>What can I gain if I lose the battle?</li>
                {faq5 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq5 && <div className='faq_contain_content'>
                <li>Both parties need to pay 50 CON as a deposit. The winner gets the cards, and the loser gets the CON (10% handling fee will be deducted, and the actual amount is 90CON).</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick6}>
                <li>What is the point for?</li>
                {faq6 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq6 && <div className='faq_contain_content'>
                <li>The point is the standard for subsequent airdrops or other rewards.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick7}>
                <li>How can I get more points?</li>
                {faq7 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq7 && <div className='faq_contain_content'>
                <li>You can get 10 points by combining constellation cards to a super card, and 1 point in battle (battle or be battled against). The more you combine or battle, the more points you can get.</li>
            </div>}
        </div>
        <div className='faq_contain'>
            <div className='faq_contain_title' onClick={handerClick8}>
                <li>What rewards can I get if I combined constellation cards?</li>
                {faq8 ? <img src={up_img}/> : <img src={down_img}/>}
            </div>
            {faq8 && <div className='faq_contain_content'>
                <li>All revenue from the application is divided into three parts: 10% is given to developers for subsequent development and system maintenance, 40% is distributed into the airdrop pool for subsequent airdrops or other rewards, and 50% is distributed into the bonus pool to reward those users who combine constellation cards to super cards(Zodiac Cards).

<br/><br/>The reward algorithm of the bonus pool is: 20% of the total remaining in the previous round + 50% of the current round.
<br/>For example, if user A has the weight value 8 when entering the bonus pool, while the total weight of the bonus pool is 1000, and the amount that can be distributed by the bonus pool is 10ETH, then user A can get (8/1000)*10 ETH, which is about 0.08ETH.</li>
            </div>}
        </div>
      </div>
      <div className='home_social'>
        <a href='https://x.com/constellationba' target="_blank"><img src={twitter_img}/></a>
        <a href='https://github.com/constellationbattle' target="_blank"><img src={github_img}/></a>
      </div>
      <div className='home_bottom'>
         <li>@2024 Constellation Battle Team. All rights reserved</li>
      </div>
    </div>
    

    )
}

export default Home